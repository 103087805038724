<template>
  <div class="header-top-nav">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- Currency Exchange Rates -->
        <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
          <div class="exchange-rates">
            <div class="rate" v-for="(exchangeRate, currency) in exchangeRates" :key="currency">
              <img :src="exchangeRate.flag" alt="" class="flag-icon">
              <span class="rate-value">
                {{ exchangeRate.rate ? formatRate(exchangeRate.rate, currency) : '---' }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-end">
          <a href="/vendedores">
            <i class="fab fa-whatsapp sales-team-icon"></i>
            <span class="sales-team-text">Equipe de Vendas</span>
          </a>
          <a href="/sobre-nos" class="about-button">
            <span class="sales-team-text">Sobre nós</span>
          </a>
        </div>
        
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import bandeiraBrasil from '@/assets/images/bandeira-brasil.webp';
import bandeiraParaguai from '@/assets/images/bandeira-paraguai.webp';
import bandeiraArgentina from '@/assets/images/bandeira-argentina.webp';
import { formatCurrency } from '../helpers/helper';

export default {
  name: 'HeaderTopNav',
  data() {
    return {
      exchangeRates: {
        'R$': { rate: null, flag: bandeiraBrasil },
        'G$': { rate: null, flag: bandeiraParaguai },
        'P$': { rate: null, flag: bandeiraArgentina }  // Nova moeda (Peso Argentino)
      },
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isMobileView() {
      return this.windowWidth < 991;
    }
  },
  async mounted() {
    this.fetchExchangeRates();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async fetchExchangeRates() {
      try {
        const response = await axios.get('https://api-genove.agcodecraft.com/api/public/exchange-rates');
        const data = response.data;

        data.forEach(rate => {
          const sourceCurrency = rate.source_currency.symbol;
          const targetCurrency = rate.target_currency.symbol;

          if (sourceCurrency === 'U$' && targetCurrency === 'R$') {
            this.exchangeRates['R$'].rate = parseFloat(rate.tax).toFixed(rate.target_currency.decimals);
          } else if (sourceCurrency === 'U$' && targetCurrency === 'G$') {
            this.exchangeRates['G$'].rate = parseFloat(rate.tax).toFixed(rate.target_currency.decimals);
          } else if (sourceCurrency === 'U$' && targetCurrency === 'P$') {
            this.exchangeRates['P$'].rate = parseFloat(rate.tax).toFixed(rate.target_currency.decimals);  // Adiciona a taxa do Peso Argentino
          }
        });
      } catch (error) {
        console.error('Falha ao buscar taxas de câmbio:', error);
      }
    },
    formatRate(rate, currencyCode) {
      let currencySymbol;

      if (currencyCode === 'R$') {
        currencySymbol = 'R$';
      } else if (currencyCode === 'G$') {
        currencySymbol = 'G$';
      } else if (currencyCode === 'P$') {
        currencySymbol = 'P$';  // Adiciona símbolo do Peso Argentino
      }

      const decimalPlaces = this.exchangeRates[currencyCode].flag === bandeiraBrasil ? 2 : 0;

      const formattedValue = parseFloat(rate).toLocaleString('pt-BR', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });

      return `${currencySymbol} ${formattedValue}`;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>

<style scoped>
.header-top-nav  {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.header-top-nav .col-lg-8,
.header-top-nav .col-lg-4 {
  flex: 1; 
}

.exchange-rates {
  display: flex;
  font-size: 0.9rem;
  white-space: nowrap;
}

.rate {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.sales-team-icon {
  margin-right: 8px;
  font-size: 0.9rem;
}

.sales-team-text {
  font-size: 0.9rem;
}

.flag-icon {
  margin-right: 5px;
  width: 16px;
  height: 11px;
}

@media (max-width: 991px) {
  .sales-team-text {
    font-size: 0.8rem; 
  }
}

@media (max-width: 576px) {
  .sales-team-text {
    font-size: 0.7rem; 
  }
}
.about-button {
  margin-left: 20px;
}
</style>
